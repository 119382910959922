@import "../../../assets/scss/mixins.scss";
@include pageTitle;
.productDetail{
    padding: 50px 0 60px;
    @include md{
        padding: 40px 0;
    }
    .categoryRow{
        display: flex;
        margin: 0 -15px;
    }
    .relatedItem{
        color: $primaryRed;
        position: relative;
        line-height: 2;
        padding-left: 30px;
        margin: 80px 0 40px;
        &::before{
            position: absolute;
            content: '';
            width: 20px;
            border-radius: 5px;
            height: 100%;
            background-color: $primaryRed;
            left: 0;
        }
    }
    .projectInfoRow{
        gap:4%;
        @include md{
            gap: 0;
            flex-direction: column;
        }
        .productImgCol{
            width: 60%;
            gap:4%;
            @include md{
                width: 100%;
            }
            @include mo{
                flex-direction: column-reverse;
            }
            .productImgBoxs{
                width: 23%;
                @include mo{
                    width: 100%;
                    display: flex;
                    grid-gap: 10px;
                    margin-top: 10px;
                }
                .productSmImage{
                    margin-bottom: 15px;
                    height: 120px;
                    @include mo{
                        height: 100px;
                    }
                    @include smmo{
                        height: 70px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }
            }
            .mainProductImg{
                width: 73%;
                @include mo{
                    width: 100%;
                }
                img{
                    width: 100%;
                    height: 525px;
                    object-fit: cover;
                    border-radius: 8px;
                    @include mo{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .productInfoCol{
            width: 36%;
            @include md{
                width: 100%;
                margin-top: 30px;
            }
            .reviewBox{
                margin-top: 15px;
                .ratingIcon{
                    margin-right: 3px;
                    width: 20px;
                }
                .review{
                    color:rgba(0,0,0,0.6);
                    padding: 0 8px;
                    border-right:1px solid rgba(0,0,0,0.6);
                    margin-right: 5px;
                }
                .stock{
                    color:#00FF66;
                }
            }
            .price{
                margin: 15px 0;
            }
            .productBuyInfo{
                margin-top: 20px;
                padding: 20px 0;
                border-top: 1px solid rgba(0,0,0,0.6);
                .colorsLabel{
                    margin-bottom: 5px;
                }
                .colorWrapper{
                    .chooseColor{
                        padding-left: 12px;
                        flex-wrap: wrap;
                        
                        .colorInputBox{
                            margin-bottom: 5px;
                            margin-right: 5px;
                            input[type="radio"] {
                                display: none;
                            } 
                            label{
                                width: 20px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                            
                            }
                              .colorBox{
                                width: 20px;
                                height: 20px;
                                display: block;
                                border-radius: 50%;
                              }
                              .borderBox{
                                display: block;
                              }
                              input[type="radio"]:checked + label {
                                border:1px solid $black
                              }
                              input[type="radio"]:checked + label .colorBox {
                                width: 12px;
                                height: 12px;
                              }
                        }
                    }
                }
                
                .sizeWraper{
                    margin:15px 0;
                    .chooseColor{
                        padding-left: 12px;
                        flex-wrap: wrap;
                        .colorInputBox{
                            margin-bottom: 5px;
                            margin-right: 5px;
                            input[type="radio"] {
                                display: none;
                            } 
                            label{
                                width: 30px;
                                height: 30px;
                                border:1px solid rgba(0,0,0,0.5);
                                border-radius: 5px;
                            }
                              .sizes{
                                 color:$black;
                                 font-size: 12px;
                                 line-height: 1;
                              }
                              input[type="radio"]:checked + label {
                                border-color:$primaryRed;
                                background-color: $primaryRed;
                              }
                              input[type="radio"]:checked + label .sizes {
                                color: $white;
                              }
                        }
                    }
                } 

                .quantityWrapper{
                    @include md{
                        max-width: 400px;
                    }
                  .quantityBtnCard{
                    border: 1px solid rgba(0,0,0,0.5);
                    border-radius: 5px;
                    .count{
                        width: 40px;
                    }
                    button{
                        border: 0;
                        background-color: transparent;
                        color:$black;
                        font-size: $font-sm;
                        padding: 5px 8px;
                        transition: 0.3s;
                        span{
                            font-size: $font-lg;
                        }
                        &.removeBtn{
                            border-right: 1px solid rgba(0,0,0,0.5);
                            border-radius: 0;
                            &:hover{
                                background-color: #DB4444;
                                color:$white;
                            }
                        }
                        &.addBtn{
                            border: 1px solid $primaryRed;
                            border-radius: 0;
                            background-color: $primaryRed;
                            color:$white;
                            margin:-1px;
                            border-radius: 0 5px 5px 0;
                            &:hover{
                                background-color: #DB4444;
                                border-color: 1px solid #DB4444;
                            }
                        }
                    }
                  }
                  .buyBtnWrapper{
                    .buyNowBtn{
                        padding: 5px;
                        font-size: $font-sm;
                        width: 130px;
                        height:32px;
                        @include mo{
                            width: 100px;
                        }
                    }
                  }
                  .favoriteBtn{
                    background-color: transparent;
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid $black;
                    width: 32px;
                    height: 32px;
                    span{
                        font-size: $font-mdlg;
                        color: $black;
                    }
                  }
                }
            }

            .deliveryWrapper{
                border: 1px solid rgba(0,0,0,0.5);
                border-radius: 8px;
                @include md{
                    max-width: 400px;
                }
                .deliveryCard{
                    padding: 15px;
                    &:first-child{
                        border-bottom: 1px solid rgba(0,0,0,0.5);
                    }
                    a{
                        color:$black;
                    }
                    .deliveryIcon{
                        margin-right: 8px;
                        width: 28px;
                    }
                }
            }
        }
    }
}