@import "../../../assets/scss/mixins.scss";

.h1 {
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-size: 24px;
  line-height: 30px;
}
.h3 {
  font-size: 20px;
  line-height: 22px;
}
.h4 {
  font-size: 18px;
  line-height: 20px;
}
.h5 {
  font-size: 16px;
  line-height: 18px;
}
.h6 {
  font-size: 15px;
  line-height: 17px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  letter-spacing: -0.015em;
  -webkit-letter-spacing: -0.015em;
  font-family: 'Poppins', sans-serif;
  @include textStyling;
}
