@import "../../../assets/scss/mixins.scss";
@include pageTitle;
.productDetail{
    padding: 50px 0 60px;
    @include md{
        padding: 40px 0;
    }
    .categoryRow{
        display: flex;
        margin: 0 -15px;
    }
    .relatedItem{
        color: $primaryRed;
        position: relative;
        line-height: 2;
        padding-left: 30px;
        margin: 80px 0 40px;
        &::before{
            position: absolute;
            content: '';
            width: 20px;
            border-radius: 5px;
            height: 100%;
            background-color: $primaryRed;
            left: 0;
        }
    }
    .projectInfoRow{
        gap:4%;
        @include md{
            gap: 0;
            flex-direction: column;
        }
        .productImgCol{
            width: 60%;
            gap:4%;
            @include md{
                width: 100%;
            }
            @include mo{
                flex-direction: column-reverse;
            }
            .productImgBoxs{
                width: 23%;
                @include mo{
                    width: 100%;
                    display: flex;
                    grid-gap: 10px;
                    margin-top: 10px;
                }
                .productSmImage{
                    margin-bottom: 15px;
                    height: 120px;
                    @include mo{
                        height: 100px;
                    }
                    @include smmo{
                        height: 70px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }
            }
            .mainProductImg{
                width: 73%;
                @include mo{
                    width: 100%;
                }
                img{
                    width: 100%;
                    height: 525px;
                    object-fit: cover;
                    border-radius: 8px;
                    @include mo{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .productInfoCol{
            width: 36%;
            @include md{
                width: 100%;
                margin-top: 30px;
            }
            .price{
                margin: 15px 0;
            }
            .ProductInfoBox{
                padding: 10px 0;
                img{
                    width: 20px;
                }
                span{
                    color:#2F2F2F;
                    font-size: $font-sm;
                    text-transform: uppercase;
                    padding: 0 8px;
                    border-right: 1px solid #2F2F2F;
                    margin-right: 8px;
                    &:last-child{
                        border-right:0;
                        margin-right: 0;
                    }
                }
            }
            .infoCard{
                padding: 15px 0;
            }
            .dis{
                color:#2F2F2F;
            }
            
            .sellerWraper{
                padding: 20px 0;
                .sellerBtn{
                    height: 45px;
                    width: 150px;
                    margin-left: 20px;
                }
                .circle{
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    background-color: $primaryRed;
                    color:$white;
                    font-size: $font-mdlg;
                    font-weight: 600;
                    flex-shrink: 0;
                    @include smmo{
                        width: 32px;
                        height: 32px;
                        font-size: $font-lg
                    }
                }
                .sellerName{
                    display: block;
                    margin: 0 15px;
                    max-width: 150px;
                    @include smmo{
                        max-width: 130px;
                        max-width: 0 10px;
                        font-size: $font-lg;
                    }
                }
                .forword{
                    padding-top: 6px;
                    span{
                        font-size: $font-lg;
                        @include smmo{
                            font-size: $font-sm;
                        }
                    }
                }
            }
        }
    }
}