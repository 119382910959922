@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/styles.scss';
.headerWraper{
    border-bottom: 1px solid #DEE2E7;
    .statusBarSection{
        background-color: $black;
        padding:12px;
        @include mo{
            padding: 5px;
        }
        span{
            @include mo{
              font-size: 10px;
            }
        }
        .statusBarwrapper{
            max-width: 542px;
            .shopNowBtn{
                background-color: transparent;
                border: 0;
                text-decoration: underline;
                color: $white;
                font-size: $font-sm;
                font-weight: 400;
                line-height: 1.5;
                padding: 1px 0px 1px 20px;
                font-size: 12px;
                @include mo{
                    padding: 1px 0px 1px 8px;
                    font-size: 12px;
                }
            }
            span{
               font-size: 13px; 
            }
        }
    }
    .logoBrand{
        img{
            width: 67%;
            @include mo{
                max-width: 60px;
            }
        }
    }
    .loginRegister{
      color: $primaryRed;
      padding: 15px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 0.2px;
      @include mo{
        padding: 5px;
      }
      span{
        padding-right: 5px;
      }
    }
    .userDP{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15px;
        @include md{
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        img{
            width: 44px;
            height: 44px;
            object-fit: cover;
            border-radius: 50%;
            @include md{
                width: 30px;
                height: 30px;
            }
        }
    }
    .headerBtnWrapper{
        button{
            background-color: transparent;;
            border: 0;
            color: $black;
            padding: 0;
            width: 32px;
            height: 32px;
            @include mo{
                width: 20px;
                height: 20px;
            }
            span{
                @include mo{
                    font-size: $font-mdlg;
                }
            }
           
            &.favBtn{
                margin:0 20px;
                @include mo{
                    margin: 0 10px;
                }
            }
        }
    }
}