@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/breakpoints.scss";

.button {
  cursor: pointer;
  border-radius: 12px;
  color: $white;
  letter-spacing: 0.46px;
  display: flex;
  font-weight: 700;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  user-select: none;
  padding: 15px;
  transition: 0.2s;
  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &.textBtn{
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  &.redBtn{
    background-color: $secondaryRed;
    color: $white;
    border-radius: 10px;
    padding: 15px 10px;
    font-size: $font-lg;
    font-weight: 500;
    border:0;
    line-height: 1.5;
    transition: 0.3s;
    &:hover{
      background-color: #DB4444;
    }
    @include mo{
      padding: 10px;
    }
  }
  &.primaryOutline{
    background-color: transparent;
    color: $black;
    border-radius: 10px;
    padding: 15px 10px;
    font-size:$font-lg;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.40);
    line-height: 1.5;
    transition: 0.3s;
    &:hover{
      background-color: #DB4444;
      color: $white;
      border-color: #DB4444;
    }
  }

  &.tabBtn{
    background-color: $white;
    border-radius: 30px;
    border: $secondaryBorder;
    box-shadow: 0px 4px 39px 0px rgba(0, 0, 0, 0.07);
    color: #484848;
    font-size: $font-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    min-width: 80px;
    padding: 10px 15px;
    span{
      font-size: $font-sm;
      margin-left: 10px;
      color: #000000;
    }
  }
}
  