@import "../../../assets/scss/mixins.scss";
@include pageTitle;
@include card;
.paymentWrapper{
    padding: 50px 0 60px;
    @include md{
        padding: 40px 0;
    }
    .card{
        margin-bottom: 2em;
        padding: 20px;
    }
    .paymentRow{
        grid-gap: 4%;
        @include md{
            flex-direction: column;
            grid-gap: 0;
        }
        .paymentMethodCol{
            max-width: 65%;
            width: 100%;
            @include md{
                max-width: 100%;
            }
            .userInfo{
                margin-top: 8px;
            }
            .redBtn{
                color:$secondaryRed;
            }
            .addressWrapper{
                margin:15px 0;
                .address{
                    max-width: 480px;
                }
                
            }
            .metehodText{
                margin-bottom: 20px;
            }
            .methodWrapper{
                margin-top:20px;
                @include md{
                    margin-top:15px; 
                }
                .plusBtn{
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;
                    margin-left: 10px;
                    @include md{
                        width: 32px;
                        height: 32px;
                    }
                }
                
            }
        }
        .paymentCol{
            max-width: 35%;
            width: 100%;
            @include md{
                max-width: 100%;
            }
           .productImgWrapper{
             .productImg{
                width: 80px;
                height: 60px;
                object-fit: cover;
                border-radius: 10px;
                margin-right: 15px;
             }
             .productName{
                margin-bottom: 5px;
             }
             .productBtnWrapper{
                background-color: $secondaryRed;
                transition: 0.3s;
                border-radius: 8px;
                padding: 5px 8px;
                button{
                    background-color: transparent;
                    border: 0;
                    padding: 3px;
                    font-size: $font-lg;
                    font-weight: 500;
                    color:$white;
                    span{
                        font-size: $font-lg;
                    }
                }
             }
             .productBtnWrapper:hover{
               background-color: #DB4444;
             }
           }
           
           .coupan{
            border: 1px dashed #000;
            padding: 10px 15px;
            margin: 20px 0;
            @include md{
                max-width: 200px;
            }
            .discount{
                width: 24px;
                height: 24px;
                margin-right: 20px;
            }
           }
           .productInfoWrapper{
            padding-top: 8px;
            .pb5{
                padding-bottom: 5px;
            }
            span{
                color: #9A8989;
                font-size: $font-sm;
            }
            .serviceCharges{
                border-top: 1px solid #9A8989;
                border-bottom: 1px solid #9A8989;
                padding: 10px 0;
                margin-bottom: 15px;
            }
           }
        }
    }
    .payBtnWrapper{
        max-width: 65%;
        text-align: center;
        @include md{
            max-width: 100%;
        }
        button{
            margin:auto;
            min-width: 180px;
        }
    }
}
