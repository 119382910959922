@import "./breakpoints.scss";
@import "./variables.scss";

@mixin textStyling {
  &.strong3 {
    font-weight: 300;
  }
  &.strong4 {
    font-weight: 400;
  }
  &.strong5 {
    font-weight: 500;
  }
  &.strong6 {
    font-weight: 600;
  }
  &.strong7 {
    font-weight: 700;
  }
  &.strong8 {
    font-weight: 800;
  }
  &.strong9 {
    font-weight: 900;
  }
  &.white {
    color: $white;
  }
  &.black{
    color:$black;
  }
  &.red{
    color: $secondaryRed;
  }
}

@mixin pageTitle{
  .pageTitel{
    font-size: $font-xlg;
    line-height: 1.25;
    letter-spacing: 1px;
    font-family: 'Inter', sans-serif;
    @include mo{
        font-size: $font-mdlg;
    }
  }
}

@mixin card{
  .card{
    border-radius: 12px;
    border: 1px solid #ECECEC;
    box-shadow: 0px 4px 39px 0px rgba(0, 0, 0, 0.09);
    padding: 15px 20px;
    @include md{
      padding: 15px;
    }
  }
}

@mixin filterModal{
  .modalOverlay{
    .modalBody{
        width: 750px;
        padding-top: 20px;
        @include laptop{
            max-width: 600px;
            width: 100%;
        }
        @include md{
            padding-top: 15px;
        }
        .modalHeader{
            border-bottom: 1px solid #dcdcdc;
            padding-bottom: 10px;
            .modalHeading{
                font-size: 24px;
                letter-spacing: 1px;
                line-height: 2;
                @include md{
                    font-size: $font-mdlg;
                }
            }
            .closeBtn{
                @include md{
                    top:20px
                }
            }
        }
        .filterList{
            border-right: 1px solid #dcdcdc;
            ul{
                padding-bottom: 45px;
                padding-top: 15px;
                @include md{
                    padding-bottom: 20px;
                }
                .filters{
                    padding-left: 38px;
                    border-left: 4px solid white;
                    color: #000000;
                    font-size:$font-smlg;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: 0.84px;
                    padding-right: 40px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    @include md{
                        padding-left: 15px;
                        border-left: 2px solid white;
                        font-size: $font-lg;
                        line-height: 32px;
                        letter-spacing: normal;
                        padding-right: 15px;
                        margin-bottom: 5px;
                    }
                    @include mo{
                        padding-left: 7px;
                        border-left: 1px solid white;
                        font-size: $font-sm;
                        line-height: 1.5;
                        padding-right: 7px;
                        margin-bottom: 5px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.active{
                        border-color: $primaryRed;
                        color: $primaryRed;
                    }
                }
            }
        }
        .filterCheckWrapper{
            padding: 15px 24px;
            @include md{
                padding: 10px 15px;
            }
            .filterCheck{
                margin-bottom: 5px;
                min-height: 36px;
                display: flex;
                align-items: center;
                @include md{
                    min-height: 30px;
                }
                @include mo{
                    min-height: 20px;
                }
            }
        }
    }
  }
}