$primary: #5286FF;
$secondary:#242450;
$lightGrey:#7C7C7C;
$white:#ffffff;
$black:#000000;
$primaryRed:#C3272E;
$secondaryRed:#9E1624;
$darkYellow:#FE8019;
$borderColor:#CFCECE;
$lightGrey:#D9D9D9;


// Border variant
$primaryBorder: 1px solid #CFCECE;
$secondaryBorder: 1px solid #E2E2E7;
// font size variables
$font-xxs:10px;
$font-xs:12px;
$font-sm:14px;
$font-md:15px;
$font-lg:16px;
$font-smlg:18px;
$font-mdlg:20px;
$font-xlg:24px;
$font-big:32px;
$font-xlarge:36px;
// BreackPoints
$screen-smmo:420px;
$screen-mo:600px;
$screen-sm:767px;
$screen-md:900px;
$screen-ipad:1024px;
$screen-laptop:1280px;
$screen-lg:1440px;