@import "../../../assets/scss/mixins.scss";
@include pageTitle;
@include filterModal;
.productDetail{
    padding: 50px 0 60px;
    @include md{
        padding: 40px 0;
    }
    .tabWrapper{
        padding: 10px 0 30px;
        button{
            margin-left: 20px;
        }
    }
    .categoryRow{
        margin: 0 -15px;
    }
}